var headerLayers = document.getElementsByClassName("header_layer");
var header = document.getElementById("header");

document.addEventListener('scroll', function (e) {
    currScroll = header.getBoundingClientRect().top;
    if (currScroll > 0)
        currScroll = 0;

    Array.from(headerLayers).forEach(headerLayer => {
        currentPositionX = getComputedStyle(headerLayer).backgroundPositionX;

        parallaxMultiplayer = headerLayer.dataset.parallax_multiplayer;
        newPositionY = -currScroll * parallaxMultiplayer;
        headerLayer.style.backgroundPosition = currentPositionX + " " + newPositionY + "px";
    });

    //document.getElementById("fps_description_holder").style.backgroundPosition = "center " + ( 600 -currScroll / 3) + "px";
    //document.getElementById("rts_description_holder").style.backgroundPosition = "center " + ( 1500 -currScroll / 3) + "px";   
});